import { Contaminants } from '../Types/Contaminants';

//const API_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/api/notes' : '/api/notes';

export const getAllContaminants = async (): Promise<Contaminants> => {
  console.log('Fetching all streams from:', `/all`);  // Log the full endpoint
  try {
    const response = await fetch("/api/contaminant/all"); // Fetch the notes
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // const data = await response.json(); // Parse the JSON data
    console.log('API response:', response); // Log the response data
    const responseBody = await response.json(); // Parse the JSON data from the readable stream
    console.log('API response body:', responseBody); // Log the parsed response data
    //unexpected token < in JSON at position 0
    return responseBody; // Return the parsed notes
  } catch (error) {
    console.error('Error fetching notes:', error);
    // return null;
    throw error;
  }
};
