import React from 'react';

const LandingPage: React.FC = () => {
    return (
        <div>
            <p>WaterWize is your go-to platform for comprehensive analysis and visual representation of Ontario's waterways and lakes. Our mission is to provide insightful data that helps understand and protect our precious water resources.</p>
            
            <h2>Product Description</h2>
            <p>WaterWize is a user-friendly app designed to track the effects of sewage emissions and industrial wastewater on streams and lakes. By combining data on water quality and environmental regulations, the app provides a clear view of the overall impact of these waste streams on natural water bodies. It helps track pollution trends, ensure compliance with regulations, and raise awareness about the health of our freshwater resources. This product will have features that will compare the quality of streams and lakes, interactive summary tables of data, and an interactive map that’s colour-coded to indicate water quality.</p>

            <h2>Product Pitch</h2>
            <p>Today, Earth’s lakes and streams are at risk from sewage and industrial waste, causing significant damage that goes unnoticed. These pollutants negatively impact human health, ecosystems and the safety of water resources. WaterWize’s goal is to change that. WaterWize provides a solution by offering updates on the effects of sewage and wastewater are impacting water quality.</p>

            <p>WaterWize will leverage data on stream and lake water quality and environmental compliance records to provide insight into how waste impacts natural water bodies. What sets WaterWize apart is its ability to offer clear, visual representations of trends, offering a level of understanding not currently available elsewhere. The members of Nodash aim to help environmental agencies, policymakers, and the public take action before irreversible lasting damage occurs.</p>

            <h2>Project Scope</h2>
            <p>WaterWize’s objective is to develop and deploy an application that can provide waterbody details and pollution sources for Guelph, Ontario. This is visualized as a dashboard that will provide comprehensive information on streams and lakes including pollution sources, compliance records and key environmental metrics. Built using Agile and SCRUM methodologies, the dashboard will prioritize the core functionalities needed to deliver accurate, real-time insights. Our ultimate goal is to expand the platform’s coverage and make a lasting impact on global water quality management..</p>
            
            <h3>Key Features</h3>
            <ul>
                <li><strong>Datasets:</strong>
                    <ul>
                        <li>Stream water quality</li>
                        <li>Inland lake water quality</li>
                        <li>Environmental compliance for sewage emissions</li>
                        <li>Industrial wastewater</li>
                    </ul>
                </li>
                <li><strong>Description:</strong>
                    <ul>
                        <li>The Water Source Impact Tracker visualizes the effects of sewage emissions and industrial wastewater on the quality of streams and lakes.</li>
                        <li>It provides insights into the cumulative impact of these waste streams on natural water bodies.</li>
                        <li>Users can explore data trends, identify pollution hotspots, and understand the broader environmental implications.</li>
                    </ul>
                </li>
            </ul>
            <h3>Users</h3>
            <li><strong>The target users:</strong>
                    <ul>
                        <li>Environmental agencies seek data to enforce regulations and monitor water quality.</li>
                        <li>Policymakers need reliable insights to shape water management policies.</li>
                        <li>Industries and municipalities are required to meet environmental standards and demonstrate regulatory compliance.</li>
                        <li>Local governments crafting by-laws to ensure clean water for communities.</li>
                        <li>Non-profit organizations and advocacy groups dedicated to raising awareness and driving environmental action.</li>
                        <li>Researchers, scientists, and educators studying pollution and its impacts on ecosystems.</li>
                    </ul>
                </li>
        </div>
    );
};

export default LandingPage;