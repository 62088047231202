import React from 'react';
import AddNote from './components/AddNote';
import NoteList from './components/NoteList';
import CurrentDate from './components/CurrentDate';


const App: React.FC = () => {
  return (
    <div className="App">
      <h1>TODO Notes Application</h1>
      <CurrentDate />
      <AddNote />
      <NoteList />
    </div>
  );
};


// const App: React.FC = () => {
//   return (
//     <div className="App">
//       <h1>TODO Notes Application</h1>
//     </div>
//   );
// };

export default App;