import React from 'react';

const CurrentDate: React.FC = () => {
    const date = new Date().toISOString().slice(0, 10); 
    return (
        
        <div>
    
            <h2>Today's Date: {date}</h2>
        
        </div>
    );
};

export default CurrentDate;
