import React, {useEffect, useState} from 'react';
import {getAllContaminants} from '../Services/contaminantService';
import { Contaminants } from '../Types/Contaminants';

const ContainmentTable: React.FC = () => {
  const [contaminantData, setContaminantData] = useState<Contaminants[]>([])

      useEffect(() => {
        const fetchData = async () => {
            try {
                let newData = await getAllContaminants();
                if (Array.isArray(newData)) {
                  setContaminantData(newData); 
                } else {
                  setContaminantData([newData]); 
                }
            } catch (error) {
                console.error('Error fetching notes:', error);
            }
        };

        fetchData();
    }, []);
   
    return (
      <div>
      <h2>Contaminant Summary Table</h2>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Last Updated</th>
            <th>District</th>
            <th>Contaminant</th>
            <th>Contaminant Limit</th>
            <th>Contaminant Unit</th>
          </tr>
        </thead>
        <tbody>
          {contaminantData.map((data: Contaminants) => (
            <tr key={data.id}>
              <td>{data.id}</td>
              <td>{new Date(data.lastUpdated).toLocaleDateString()}</td>
              <td>{data.district}</td>
              <td>{data.contaminant}</td>
              <td>{data.contaminantLimit}</td>
              <td>{data.contaminantUnit}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

    );
};

export default ContainmentTable;