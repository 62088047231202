// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav {
  background-color: #0288c6; 
  padding: 0.8rem;
  width: 100%;
  position: fixed; 
  top: 0;
  left: 0;
  z-index: 1000;
}

.content {
  margin-top: 2.5rem; 
  padding: 1rem; 
}

.App-header {
  padding-top: 3rem; 
}

nav ul {
  list-style-type: none;
  display: flex;
  justify-content: center; 
  margin: 0;
  padding: 0;
}

nav ul li {
  margin: 0 1rem; 
  text-align: center;
}

nav ul li a {
  text-decoration: none;
  color: #000;
  font-weight: bold;
  padding: 0.25rem 1rem; 
  border-radius: 5px;
}

nav ul li a:hover {
  color: #46e0e3;
}

nav ul li a.active {
  background-color: #d3d3d3; 
  color: #000; 
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,WAAW;EACX,eAAe;EACf,MAAM;EACN,OAAO;EACP,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,uBAAuB;EACvB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,iBAAiB;EACjB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\nnav {\n  background-color: #0288c6; \n  padding: 0.8rem;\n  width: 100%;\n  position: fixed; \n  top: 0;\n  left: 0;\n  z-index: 1000;\n}\n\n.content {\n  margin-top: 2.5rem; \n  padding: 1rem; \n}\n\n.App-header {\n  padding-top: 3rem; \n}\n\nnav ul {\n  list-style-type: none;\n  display: flex;\n  justify-content: center; \n  margin: 0;\n  padding: 0;\n}\n\nnav ul li {\n  margin: 0 1rem; \n  text-align: center;\n}\n\nnav ul li a {\n  text-decoration: none;\n  color: #000;\n  font-weight: bold;\n  padding: 0.25rem 1rem; \n  border-radius: 5px;\n}\n\nnav ul li a:hover {\n  color: #46e0e3;\n}\n\nnav ul li a.active {\n  background-color: #d3d3d3; \n  color: #000; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
